@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
*{
  font-family: "Poppins", sans-serif;
}

header{
    border-bottom: 4px solid  #00384c;
    border-radius: 0 0 50px 0 ;
    background-color: white;
    z-index: 9999;
    position: relative;
    /* position: fixed;
    top: 0;
    width: 100%;
    left: 0; */
}
a.nav-link {
  font-size: 14px;
  color: #00384c;
}

.back-change {
  background-color: #00384c;
  color: white;
  border-radius: 0 0 40px 0;
  box-shadow: rgb(0 56 76 / 30%) 0px 1px 2px 2px, rgb(0 56 76 / 9%) 2px 0px 0px 0px;
}

.social1 {
  display: flex;
  justify-content:start;
}

.social1 i {
  width: 50px;
  height: 50px;
  color: #00384c;
  background-color: white;
  font-size: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  border-bottom: 1px solid #0080ff;
  border-radius: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.icn-cl {
    animation: callring 2s infinite;
   animation-iteration-count: infinite;
}
a.nav-link {
    color: black;
}
.logo-width{
    width: 130px;
}

.trav_engine {
    position: relative;
    /* width: 230px; */
}

.trav_toggle {
    width:100%;
    height: 100%;
    box-sizing: border-box;
    /* line-height: 32px; */
    font-size: 0;
    position: absolute;
    top: 0;
}
.trav_form {
  border: 2px solid #00384c;
  width: 408px;
  box-sizing: border-box;
  float: left;
  position: absolute;
  background: white;
  z-index: 99999;
  border-radius: 10px;
}
.trav_item {
    border-bottom: 1px solid #cfc8c8;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    float: left;
    border-radius: 20px;
}
.trav_inner1 {
    width: 58%;
    float: left;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    box-sizing: border-box;
}
.trav_inner1 span {
    font-size: 11px;
    display: block;
    color: #888888;
}
.trav_inner2 {
    width: 42%;
    float: left;
    font-size: 13px;
    line-height: 30px;
    color: #666;
    box-sizing: border-box;
    display: flex;
}
.minus, .plus {
  border: 1px solid #093f52;
    width: 36px;
    text-decoration: none;
    font-size: 18px;
    height: 34px;
    text-align: center;
    box-sizing: border-box;
    float: left;
    background: #073d4e;
    color: white;  
    /* background: rgb(255,255,255); */
    /* background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 48%, rgba(255,255,255,1) 100%);  */
    /* background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(242,242,242,1) 48%,rgba(255,255,255,1) 100%); */
    /* background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(242,242,242,1) 48%,rgba(255,255,255,1) 100%);  */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0);
}
.minus {
    border-radius: 50% 0 0 50%;
}
.plus {
    border-radius: 0 50% 50% 0;
}
.txt_trav {
    outline:none;
    border: 1px solid #ddd;
    height: 34px;
    width: 36px;
    float: left;
    text-align: center;
    line-height: 34px;
    box-sizing: border-box;
    border-left: 0;
    border-right: 0;
    font-size: 16px;
    color: #444;
}
.txt_Traveler {
    border: 1px solid #ddd;
    /* width: 220px; */
    box-sizing: border-box;
    /* padding: 10px; */
    outline: none;
}

.trav_done {
  padding: 6px;
  display: block;
  border-radius: 8px;
  background: #00384c;
  font-size: 14px;
  border: 1px solid #ddd;
  color: #fff;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  cursor: default;
}
/* .trav_toggle.open {
    background: url(https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/down4-512.png) no-repeat right 8px;
    background-size: 20px;
}
.trav_toggle.close {
    background: url(https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/up4-512.png) no-repeat right 8px;
    background-size: 20px;
} */

article{
    display: none;
  }
  article.on{
    display: block;
  }
  
  .main-bg {
    margin: 20px 0;
    padding: 18px 50px;
    background: rgba(255, 255, 255, 0.55);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border-radius: 20px;
    border: 2px solid rgb(0 56 76);
}
video{
    border-radius: 50px;
}
.form-control {
  line-height: 1.9;
  border-radius: 10px;
  padding-left: 45px !important;
  border: 1px solid #00384c;
}
.form-floating:focus{
  font-size: 11px !important;
}
.form-floating>label{
  padding-left: 47px !important;
}
.space-top{
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center
}
.rounde{
    border-radius: 50px;
    height: 200px;
}
.rounde1{
  height: 200px;
  padding: 0 10px;
  border-radius: 40px 40px 0 40px;
  position: relative;
}

.card {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: #000; */
    display: flex;
    flex-direction: column;
    /* justify-content: end; */
    padding: 12px;
    gap: 10px;
    border-radius: 8px;
    cursor: pointer;
    /* color: white; */
  }
  
  .card::before {
    content: '';
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(-45deg, #00384ca8 0%, #00384c 100% );
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card::after {
    content: "";
    z-index: -1;
    position: absolute;
    inset: 0;
    background: linear-gradient(-45deg, #0080ff 0%, #00384c 100% );
    transform: translate3d(0, 0, 0) scale(0.95);
    filter: blur(20px);
  }
  
  .heading {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 700;
  }
  
  .card p:not(.heading) {
    font-size: 14px;
  }
  
  /* .card p:last-child {
    font-weight: 600;
  } */
  
  .card:hover::after {
    filter: blur(30px);
  }
  
  .card:hover::before {
    transform: rotate(-90deg) scaleX(0.8) scaleY(1.3);
}
  
  section{
    padding: 50px;
  }
  .btn-clr-chng {
    background-color: #00384c;
    border-color: #00384c;
    width: 100%;
    border-radius: 9px;
    padding: 10px;
    height: 100%;
}
  .btn:hover{
    border-color:   white;
    background-color: #00384c;
  }

  .tab-buttons {
    display: flex;
    gap: 8px;
  }
  .tab-btn {
    width: 95px;
    color: #ffffff;
    background-color: #f0f8ff00;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  .tab-btn:hover {
    background-color: #0080ff;
    color: white;
  }
  .tab-btn.active {
    background-color: #0080ff;
    color: white;
}
  
  .content {
    display: none;
  }
  .content.show {
    display: flex;
    gap: 14px;
    border-radius: 0px 0px 8px 8px;
    border-bottom: 1px solid white;
    padding: 16px;
}
  
  .content svg {
    width: 50%;
    height: 200px;
  }
  footer {
    padding-bottom: 15px;
    background-color: #00384c;
    /* background: linear-gradient(to right, rgb(0 56 76 / 50%), rgb(0 56 76 / 50%)), url(../img/footer-bg.jpg); */
    background-position: center;
    position: relative;
    padding-top: 95px;
    color: white;
}
.reddf{
  position: absolute;
  width: 100%;
  filter: brightness(0) invert(1);
  bottom: 0;
  opacity: 0.3;
}
  footer ul{
    padding-left: 0;
  }
  footer ul li{
    list-style: none;
  }
  
  .imge-round{
    border-radius: 40px 40px 0 40px;
  }
  .draw {
    position: absolute;
    top: -16px;
    left: 0;
    width: 181px;
  }
  .rela{
    position: relative;
  }
  .roung{
    border-radius: 0 100px 0 100px;
    height: 400px;
  }
  .circular{
    animation: rotate 5s infinite linear;
  }
  .loader-section {
    position: relative;
    top: 50%;
    width: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #00384c;
    padding: 10px;
    border: 2px dotted black;
    outline: 2px dashed black;
    border-radius: 20px;
} 
.loader-section:before {
  position: absolute;
  content: "";
  bottom: -23px;
  right: -23px;
  width: 100px;
  height: 100px;
  background: linear-gradient(45deg, #00384c, #00384c3d);
  z-index: -999;
  border-radius: 50%;
}
.loader-section:after {
  position: absolute;
  content: "";
  top: -20px;
  left: -37px;
  width: 100px;
  height: 100px;
  background: linear-gradient(45deg, #00384c, #00384c3d);
  z-index: -999;
  border-radius: 50%;
}
.drag {
  background: #ffffff;
  padding: 0 10px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin: 12px;
}
.drag:after {
  position: absolute;
  content: "";
  bottom: -11px;
  right: -21px;
  width: 180px;
  height: 30px;
  rotate: 172deg;
  border-radius: 50%;
  background: #00384c21;
}
  .clr-chng{
    accent-color:#00384c ;
  }
  .loader {
    position: absolute;
    top: 0;
    left: 46px;
    background: #00384c;
    width: 100%;
    height: 100%;
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    border-radius: 20px;
}
.loader div {
  background: #fff;
  width: 148px;
  height: 32px;
  animation: loader 1.2s infinite;
}
  
  .loader div:nth-child(1){
    background: #00384c;
    animation-delay: -0.40s;
  }
 
        #button{
  display:block;
  margin:20px auto;
  padding:10px 30px;
  background-color:#eee;
  border:solid #ccc 1px;
  cursor: pointer;
}
#overlay{	
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height:100%;
  display: none;
  background: rgba(0,0,0,0.6);
}
.cv-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
}
.spinner {
  width: 40px;
  height: 40px;
  border: 4px #ddd solid;
  border-top: 4px #2e93e6 solid;
  border-radius: 50%;
  animation: sp-anime 0.8s infinite linear;
}

.is-hide{
  display:none;
}

.cvkfujg {
  background: #00384c0d;
}
.plan-fly{
  position: absolute;
    top: 5px;
    right: 100px;
    opacity: .9;
    z-index: -1;
    animation: cloud 5s linear 0s infinite reverse;
}
.plan-fly1{
  position: absolute;
    top: 20px;
    right: 0px;
    opacity: .9;
    z-index: 99;
    animation: cloud1 5s linear 0s infinite reverse;
}
.plan-fly2{
  width: 50px;
}
.plan-fly12{
  width: 50px;
  transform: scaleX(-1);
}
.spac{
  width: 44%;
}

.form-select {
  padding: .375rem 2.25rem 0.375rem 1.75rem;
  border: 1px solid #00384c;
  border-radius: 20px 20px 0 20px;
}
.posi-ab {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
}


.button12 {
  width: 128px;
  padding: 3px 24px;
  position: relative;
  display: block;
  text-decoration: none;
  overflow: hidden;
  border: 0;
  cursor: pointer;
  border-radius: 9px;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);
  margin-left: 20px;
}

.button12:hover .wave12 {
  top: -120px;
}

.text12 {
  position: relative;
  z-index: 1;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.wave12 {
  width: 200px;
  height: 200px;
  background-color: #00384c;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: -70px;
  transition: 0.4s;
}

.wave12::before, a.wave12::after {
  width: 200%;
  height: 200%;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
}

.wave12::before {
  border-radius: 45%;
  background-color: #1b617a;
  animation: wave12 5s linear infinite;
}

.wave12::after {
  border-radius: 40%;
  background-color: rgba(20, 20, 20, 0.5);
  animation: wave12 10s linear infinite;
}


.obrt{
  overflow-x: hidden;
}
.arrow1 {
  animation: slide1 1s ease-in-out infinite;
  margin-left: 9px;
}
.read {
  position: absolute;
  width: 100%;
  top: 18px;
  left: 0;
  background: #ffffffc7;
  color: black;
}
.slick-next:before, .slick-prev:before {
  font-family: slick;
  font-size: 39px;
  line-height: 1;
  opacity: 1;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-next {
  right: 60px;
  top: -55px;
}
.slick-prev {
  right: 113px;
  left: inherit;
  top: -55px;
}

.chngt {
  border-left: 3px solid #00384c;
  color: black;
  padding: 10px;
  border-radius: 5px;
  background: #00384c14;
}
.paper{
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 96px;
    background-size: contain;
    background-repeat: repeat-x;
    z-index: 1;
    background-image: url("../img/dy.webp");
}
.paper1{
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 96px;
  rotate: 180deg;
  background-size: contain;
  background-repeat: repeat-x;
  z-index: 1;
  background-image: url("../img/dy.webp");
}
.card3rr3 {
  width: 85%;
  height: 100%;
  background: #00384c;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* place-content: center;
  place-items: center; */
  overflow: hidden;
  border-radius: 20px;
  padding: 20px 0;
}
.card3rr3 .drag12 {
  z-index: 1;
  background: rgb(255, 255, 255);
  border-radius: 19px 10px 0 10px;
  padding: 3px 9px;
  margin: 2px 0;
  width: 70%;
  height: 50px;
}
.card3rr3 .drag12 img{
  width: 137px;
}
.card3rr3::before {
  content: '';
  position: absolute;
  width: 120px;
  background-image: linear-gradient(180deg, rgb(27 97 122), rgb(27 97 122));
  height: 212%;
  animation: rotBGimg3rr3 25s linear infinite;
  transition: all 0.2s linear;
}
.wrfdsdf{
  background-color: #00384c;
  width: fit-content;
  padding: 2px 20px;
  border-radius: 20px;
}
.card3rr3::after {
  content: '';
  position: absolute;
  /* background:  url(../img/te.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  center; */
  background-color: #00384c;
  inset: 5px;
  border-radius: 15px;
}
.fdgrdt{
  display: none;
}
img.favi-wid{
  width: 35px !important;
}
.flow {
  width: 32px !important;
  background-color: #00384c;
  rotate: 270deg;
  padding: 3px;
  border-radius: 50%;
  margin-right: -22px;
  z-index: 9;
}
.contenu12 {
  position: relative;
  display: inline-block;
  background: linear-gradient(to right, #00384ceb, #00384ceb);
  border: none;
  border-radius: 25px;
  color: #ffffff;
  padding: 30px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  animation: glowing-pulse213 2s infinite;
}
/* #uftg{
  display: none;
} */
.contenu12::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 30px;
  box-shadow: 0 0 20px #00384c;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.contenu12:hover::before {
  opacity: 1;
}
.sizing{
  font-size: 13px;
}
.sfre {
  font-weight: 600;
  position: absolute;
  top: 2px;
  left: -13px;
}
footer h4{
  position: relative;
}
footer h4:before{
  content:"";
  width: 60px;
  height: 2px;
  background-color: white;
  position: absolute;
  left: 0;
  bottom: -8px;
}
footer h4:after{
  content: "";
  width: 5px;
  height: 5px;
  outline: 4px solid white;
  border-radius: 50%;
  background-color: #00384c;
  position: absolute;
  left: 4px;
  bottom: -9px;
  animation: leftToRight13344 infinite 5s linear;
}
.main-banner-im{
  background: url(../img/Farelist-banner.webp);
  background-repeat: no-repeat;
  background-size: cover;
    position: relative;
    z-index: 99999;
}
.form-check-input{
  border: var(--bs-border-width) solid #00384c;
}
.fggd {
  width: fit-content;
  border: 1px solid white;
  padding: 11px 100px;
  font-size: 20px;
  font-weight: 500;
}
.rydse{
  width: 100%;
}
.icon-xchng {
  position: absolute;
  width: 36px;
  height: 36px;
  background: white;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  right: 22px;
  margin: 0;
  top: 63px;
  left: 46%;
  border: 1px solid #00384c;
  padding: 4px;
  rotate: 90deg;
  z-index: 999;
}
.arrow-right {
  animation: slide124 1s ease-in-out infinite;
} 
.arrow-right1 {
  animation: slide124 1s ease-in-out infinite;
} 
.solic {
  border-left: 4px solid #00384c;
  border-radius: 40px 40px 0 40px;
  background: white;
  padding: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 100%;
}
.rujt{
  border-left: 4px solid #00384c;
  border-radius: 40px 40px 0 40px;
}
.contefrt{
  padding: 10px;
  padding-bottom: 35px;
}
.spin2323 {
  animation: spin2323 var(--duration, 40s) infinite linear;
}
.posir {
  position: absolute;
  top: -167px;
  left: -163px;
  z-index: -1;
  opacity: 0.6;
}
.odhyr{
  overflow: hidden;
}
.pldn {
  position: absolute;
  top: -32px;
  right: 8px;
  z-index: 999999;
  animation: fadeinout456 4s infinite;
}
a{
  text-decoration: none;
  color: inherit;
}

.btnff {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #00384c;
  min-width: 200px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  padding: 16px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}

.btnff:hover {
  opacity: .95;
}

.btnff .animationff {
  border-radius: 100%;
  animation: rippleff 0.6s linear infinite;
}
.form-ico{
  position: absolute;
  top: 50%;
  left: 23px;
  transform: translate(0%, -50%);
  z-index: 9;
  width: 20px;
}
.form-ico1{
  position: absolute;
  top: 50%;
  left: 11px;
  transform: translate(0%, -50%);
  z-index: 9;
  width: 20px;
}
/* input::placeholder,
input[type=text]{
  color: #595c5f83 !important;
} */
/* input[type=text]{
  color: #595c5f83 !important;
} */
.hudt {
  background: white;
  padding: 4px;
  width: 73px;
  text-align: center;
  border-radius: 8px 8px 0 8px;
  color: #00384c;
  font-weight: 900;
  margin-bottom: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.tab-brder {
  background: white;
  width: fit-content;
  text-align: center;
  border-radius: 20px 20px 0 20px;
  padding: 2px 8px;
  border: 1px solid #00384c;
}
.okht {
  background-color: #00384c;
  padding: 4px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  border-radius: 20px 20px 20px 20px;
}
.wrse{
  font-size: 13px;
}
.btn-primary:active,
.btn-primary:focus{
  color: #fff !important;
  background-color: #00384c !important;
  border-color: #00384c !important;
}
.zed{
  z-index: 999;
}
.bred li {
  /* margin: 0 10px; */
  list-style: none;
}

.bred {
  color: white;
  display: flex;
  justify-content: center;
  padding: 0;
}
.bred>li+li:before {
  margin: 0 10px ;
  color: #c5c5c5;
  content: "/\00a0";
}
.bred-ban{
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../img/tr.jpg') !important;
  position: relative;
  overflow: hidden;
}
.bred-ban::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url(../img/45f.png) !important;
  background-size: cover;
  background-position: bottom;
  width: 34%;
  height: 100%;
  
}
.bred-ban::after {
  content: "";
  position: absolute;
  bottom: -80px;
  left: 0;
  background-image: url(../img/45f.png) !important;
  background-size: cover;
  background-position: bottom;
  width: 34%;
  height: 100%;
  rotate: 180deg;
}
.cir99o img{
  width: 35px;
}
.cir99o {
  width: 246px;
  height: 246px;
  border-radius: 50%;
  margin: 10px;
  border: 4px solid #00384c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  background: #00384c0a;
}
.loader99o {
  width: 33em;
  height: 33em;
  font-size: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader99o .face99o {
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  animation: animate99o 3s linear infinite;
}

.loader99o .face99o:nth-child(1) {
  width: 78%;
  height: 78%;
  color: #00384c;
  border-color: currentColor transparent transparent currentColor;
  border-width: 0.2em 0.2em 0em 0em;
  --deg: -45deg;
  animation-direction: normal;
}

.loader99o .face99o:nth-child(2) {
  width: 70%;
  height: 70%;
  color: #00384c;
  border-color: currentColor currentColor transparent transparent;
  border-width: 0.2em 0em 0em 0.2em;
  --deg: -135deg;
  animation-direction: reverse;
}

.loader99o .face99o .circle99o {
  position: absolute;
  width: 50%;
  height: 0.1em;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: rotate(var(--deg));
  transform-origin: left;
}

.loader99o .face99o .circle99o::before {
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  content: '';
  width: 1em;
  height: 1em;
  background-color: currentColor;
  border-radius: 50%;
  box-shadow: 0 0 2em,
                0 0 4em,
                0 0 6em,
                0 0 8em,
                0 0 10em,
                0 0 0 0.5em rgba(255, 255, 0, 0.1);
}
.back-fm-clr{
  background: linear-gradient(181deg, #00384c, #ffffff00);
}
iframe {
  border-radius: 50px;
}
.ubthop {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 50px 45px;
  margin-bottom: 58px;
  background-color: white;
  border-radius: 50px 0;
}
.partition{
  border-right: 1px solid #ffffff4d;
  width: 4px;
  height: 482px;
}
.pr-pol{
  padding: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.linfg {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 10px 30px;
    height: 100%;
}
.linfg li {
  list-style: none;
  position: relative;
  margin-bottom: 5px;
}
.linfg li::before{ 
  content: "\00BB"; 
  position: absolute;
  left: -13px;
  font-weight:900;
} 
.linfg li a{
  font-size: 14px;
  font-weight: 600;
  color: #383838;
}

#button2424 {
  display: inline-block;
  border: 1px solid white;
  background-color: #00384c;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  right: 30px;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 9999999;
}
#button2424::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 23px;
  color: #fff;
}
#button2424:hover {
  cursor: pointer;
  background-color: #000000;
}
#button2424:active {
  background-color: #000000;
}
#button2424.show {
  opacity: 1;
  visibility: visible;
}
.ungy {
    position: absolute;
    top: 1px;
    left: 30px;
    font-size: 22px;
    border-radius: 64px;
    text-shadow: 0 0.36px 2.896px #2c482e, 0 -2px 1px #000000;
    letter-spacing: 0px;
    color: #ffffff;
    height: 100%;
}
.ovrufv{
  overflow: hidden;
  border-radius: 40px 40px 0 40px;
}
footer label{
  color: #00384c;
}
.fotr-bn{
  background-color: #00384c;
  border: 1px solid white !important;
}
footer .form-floating>.form-control {
  min-height: 53px !important;
  height: 53px !important;
}
.ding{
  display: flex;
}
.fredt{
  position: absolute;
  bottom: 13px !important;
  left: 50% !important;
  transform: translate(-50%);
}
.mr-topp{
  margin-top: -38px;
}
.form-check-input:checked {
  background-color: #00384c;
  border-color: #00384c;
}
.form-check-input:focus {
  box-shadow: 0 0 0 .25rem rgb(0 56 76 / 18%);
}
.sfrwsr1{
  padding-top: 15px;
}
.addr{
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  right: 0;
}
.edi:hover::before {
  transform: rotate(-90deg) scaleX(0.85) scaleY(1.2) !important;
}
.imge-round:hover{
  transform: scale(1.1);
}
.imge-round  {
  transition: 0.5s ease-in-out;
}
.swert{
  overflow: hidden;
  border-radius: 40px 40px 0 40px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.rft{
  padding: 9px 0;
}
.ion-f{
  left: 26px !important;
  top: 26px !important;
}
.form-control.is-valid, .was-validated .form-control:valid {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ffffff'%3E%3Ccircle cx='12' cy='12' r='10' style='fill:%2328a745;' /%3E%3Cpath d='M8.293 15.707l-3.5-3.5a1 1 0 1 1 1.414-1.414l2.793 2.793 6.793-6.793a1 1 0 0 1 1.414 1.414l-7.5 7.5a1 1 0 0 1-1.414 0z' style='transform: translate(1px, 1px);'/%3E%3C/svg%3E") !important;
  }
  .form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
   
}
.ani-sec {
  position: relative;
  text-align: center;
}

.ani-sc-im {
  width: 400px;
  height: 400px;
  border-radius: 50px;
  position: relative;
  z-index: 999;
  border-left: 8px solid #00384c;
  border-bottom: 8px solid #00384c;
}
.ani-sc-im:hover {
  border-right: 8px solid #00384c;
  border-left: 0;
}
.ritnty {
  width: 140px !important;
  margin: 8px;
}

.package-ribbon-wrapper {
  position: absolute;
  right: -8px;
  top: 22px;
}
.package-type {
  padding: 9px 12px;
  font-size: 12px;
  line-height: 1;
  background-color: #00384c;
}
.package-type, .package-type a {
  color: #fff;
}
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}
.package-type-gimmick {
  width: 0;
  height: 0;
  float: right;
  border-width: 8px 8px 0 0;
  border-style: solid;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: #000;
}
.destina {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 95%;
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  background: #0000009c;
  color: white;
  align-items: center;
  border-radius: 5px;
}
.tijmh {
  text-align: center;
  padding: 5px;
  font-size: 14px;
  color: #4c4c4c;
}
.dtgd .slick-prev {
  right: inherit;
  left: -45px !important;
  top: 50%;
}
.dtgd .slick-next {
  right: -26px;
  top: 50%;
}
.inner-card{
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 8px;
  padding: 10px;
}
.ftg{
  background: #00384c;
  width: min-content;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.ftg img{
  filter: brightness(0) invert(1);
  width: 50px;
}
.inner-card:hover {
  background: #00384c;
  color: white;
}
.inner-card:hover .ftg{
  background-color: white;
}
.inner-card:hover .ftg img{
  filter: brightness(1) invert(0);
}
.icon-xchng1 {
  position: absolute;
  width: 36px;
  height: 36px;
  background: white;
  border: 1px solid #00384c;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  right: 22px;
  margin: 0;
  top: 28px;
  left: 171px;
  padding: 4px;
  rotate: 90deg;
  z-index: 999;
}
.thyt {
  width: inherit !important;
  margin: 0 20px !important;
}
.erfd{
  width: 130px !important;
  margin-right:  10px;
}
.yhftr{
  width: 100px;
}
.dgfyut{
  font-size: 16px;
}
.srghy{
  rotate: 90deg;
}
.dsger4{
  padding: 17px 0;
}
.dsger4::before{
  display: none;
}
.degt56t{
  padding: 10px !important;
  z-index: 999;
}
.deted{
  width: 100%;
}
.eteer5{
  width: fit-content;
  padding: 3px 20px;
  font-size: 18px;
}
.outh::before {
  content: "";
  position: absolute;
  width: 400px;
  height: 400px;
  border: 38px solid #00384c14;
  border-radius: 50%;
  top: -215px;
  left: -183px;
}
.outh::after {
  content: "";
  position: absolute;
  width: 400px;
  height: 400px;
  border: 38px solid #00384c14;
  border-radius: 50%;
  bottom: -215px;
  right: -183px;
}
.etfstyj{
  overflow: hidden;
}
.sirnb{
  padding: 4px;
  top: 0;
  background: #000000;
  position: absolute;
  left: 0;
  color: white;
  font-size: 12px;
}
.dtgtth{
  position: absolute;
    top: 50%;
    right: -50px;
    transform: translate(0, -50%);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.dtgtth1{
  position: absolute;
    top: 50%;
    left: -33px;
    transform: translate(0, -50%);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.dtgtth1 img{
  transform: scaleX(-1);
}
.saedrt{
  padding: 22px;
  text-align: center;
}
.redfg{
  rotate: 180deg;
}
.fy6ns{
  font-size: 14px;
  border-top: 2px solid #00384c;
  padding-top: 15px;
}
p{
  font-size: 15px;
}
.ujhyt{
  border-left: 4px solid #00384c;
}
.orfw{
  border: 5px solid #00384c;
  border-radius: 20px 0 0 0;
}
.ihtf {
  position: absolute;
  z-index: 999999;
  bottom: 12px;
  right: 20px;
  border: 5px solid #00384c;
  border-radius: 0 0 20px 0;
}
.rughtd::before {
  content: "";
  position: absolute;
  top: -91px;
  right: -70px;
  width: 300px;
  height: 300px;
  background-color: #00384c14;
  border-radius: 50%;
}
.bujhu{
  border-radius: 60px 0;
}
.gur {
  background: #00384c;
  width: min-content;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}
.gur img {
  filter: brightness(0) invert(1);
  width: 45px;
}
.inrt {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 10px;
  border-radius: 100px;
  margin-bottom: 20px;
  height: 100%;
}
.ubtddea{
  border-radius: 60px 0;
  border-left: 3px solid #00384c;
  border-bottom: 3px solid #00384c;
}
.nosfth {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  height: 100%;
}
table {
  width: 94%;
  margin: auto;
  max-width: 1200px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: relative;
}

table, th, td {
  border: 2px solid #eee;
  border-collapse: collapse;
  background-color: #ffffff;
}
thead {
  position: relative;
}
/* thead tr {
  position: sticky;
  top: 10px;
} */
th {
  color: #002192;
  font-weight: 550;
  font-size: 16px;
}
th, td {
  padding: 15px;
  text-align: center;
}
td {
  font-weight: 400;
}
td a {
  font-size: 15px;
  color: #303030;
}
.backt {
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translate(0, -50%);
  width: 40px;
  height: 40px;
}
.fgdgrr{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* .fgdf{

} */
.fgdgrr .row{
  width: 75%;
}
.edryte{
  color: #00384c;
}
.erfe {
  left: 10px !important;
  width: 21px !important;
}
.erfe1 {
  left: 4px !important;
  width: 20px !important;
}
.ertsg{
  padding-left: 25px !important;
  font-size: 13px;
}
.trhth>label {
  padding-left: 27px !important;
  font-size: 15px !important;
}
.rt7tur{
  width: 28px !important;
  height: 28px !important;
  top: 33px !important;
  left: 239px !important;
  padding: 1px !important;
    rotate: 0deg !important;
}
.form-floating>.form-control:not(:placeholder-shown)~label{
  color: rgb(0 0 0);
}
.citgh {
  text-align: center;
}
.citgh img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.adsdr{
  width: 80%;
}
.fgyf1{
  border-left: none !important;
  border-radius: 0 10px 10px 0;
}
.fgyf{
  border-right: none !important;
  border-radius: 10px 0 0 10px;
}
.safasf{
  margin-left: 4px;
}
.asdfgsd {
  border: 1px solid #093f51;
  background: #00384c;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 0;
  position: absolute;
  top:60px;
  z-index: 999999;
  width: 96%;
}
.asdfgsd li {
  list-style: none;
  border: 1px solid #093f51;
  background: white;
  border-radius: 6px;
  padding: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.asdfgsd li:hover {
  border: 1px solid white;
  background: #00384c;
  color: white;
}
.asdfgsd li:hover img {
  filter: brightness(0) invert(1);
}
.dger {
  width: 20px;
  margin-right: 4px;
}

.srtsd li{
  font-size: 11px !important;
}
.indyt{
  padding-left: 30px !important;
}
.usnrr>label {
    padding-left: 32px !important;
}
.hed{
  background: #00384c;
  color: white;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.sarsdr{
 width: 34px;
}
.hedwse{
  padding: 16px;
  border-bottom: 1px dashed #9d9d9d;
}
.orfw:hover{
  z-index: 9999999;
}
.dgsdgvsd1{
  border: 1px solid white;
}
.iwnby {
  background: #00384c29;
  padding: 20px;
  border-radius: 10px 10px 0 10px;
  font-size: 17px;
}

.iwnby:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modifySearchInfoBox {
	display: none;
  padding: 8px 10px;
	background: #00e5ff;
	font-size: 20px;
  color: black;
	white-space: nowrap
}
@media (max-width: 768px) {
  .modifySearchInfoBox {
    display:block !important;
    font-size: 15px
  }
}

.rdrDefinedRangesWrapper{
  display: none;
}
.rdrDateRangePickerWrapper{
  position: absolute;
  z-index: 99;
}
.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}
.rdrDayToday .rdrDayNumber span:after {
  background: #5948db;
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background: rgb(89 72 219);
}
.rdrDateDisplayItemActive {
  border-color: rgb(89 72 219);
}
.rdrDayHovered:hover{
  border-color: rgb(89 72 219) !important;
}
.rdrCalendarWrapper {
  height: fit-content;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
  overflow: hidden;
}
.rdrWeekDay {
  font-weight: 500;
  color: rgb(34 27 87);
  font-size: 13px;
}
.rdrMonthName {
  text-align: center;
  font-weight: 600;
  color: #271e66;
  position: absolute;
  top: -58px;
  width: 100%;
  left: 0;
  font-size: 18px;
}
.rdrMonth {
  height: fit-content !important;
  position: relative;
}
.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
  z-index: 9999;
  position: relative;
}
.rdrMonthAndYearPickers {
  font-weight: 600;
  display: none;
}
.rdrDayNumber{
  font-weight: 500;
}
.rdrCalendarWrapper {
  height: fit-content;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
  overflow: hidden;
}
.rdrMonthAndYearWrapper{
padding-top: 0 !important;
background: #5948db30;
}

.rdrDateDisplayWrapper {
  background-color: rgb(239, 242, 247);
  display: none;
}
.rdrMonths:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 96%;
  border-right: 1px dashed #c7c7c7;
}

.rdrMonths {
  display: flex;
  position: relative;
}
.rdrDateRangePickerWrapper {
  position: absolute;
  z-index: 99;
  width: fit-content;
  margin-top: -10px;
  padding: 0;
}